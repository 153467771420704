import React from 'react';
import { GetServerSideProps } from 'next';

import {
	addApolloState,
	DEFAULT_LOCALE,
	getAddonsQueryVariables,
	getCountryCode,
	initializeApollo,
	Queries,
	SsrAuthQueries,
	isProfileComplete,
	createURL,
	getLaunchDarklyServerClient,
	LDContext,
	getSHA256Hash
} from 'common';
import { MediathekHomePageA } from '../containers/MediathekHome/MediathekHomeA';
import {
	getTopicsSearchQuery,
	TOPICS_PER_PAGE
} from '../containers/MediathekHome/MediathekHome.utils';
import {
	CurrentUserSpeciality,
	CurrentUserSpecialityQuery,
	RecentlyStartedCourses,
	RecentlyStartedCoursesQuery,
	SearchForTopics,
	SearchForTopicsQuery,
	TopicsForSelector,
	TopicsForSelectorQuery,
	TopicSortOrder,
	TopicSortOrderQuery
} from '../graphql/catalog/queries';
import { MediathekCourses, MediathekCoursesQuery } from 'catalog-lib/src/graphql/queries';
import { ACTIVE_TOPIC_CODE } from '../utils/topics';
import {
	getCustomTopicSortOrder,
	getFetchCoursesVariables
} from '../containers/MediathekHome/CoursesSection/CoursesSection.utils';
import { getInitialTopic } from '../utils/ssr/topic';
import { MediathekHomePageB } from '../containers/MediathekHome/MediathekHomeB';
import type { NextPageWithLayout } from './_app';

interface PageProps {
	initialTopicCode: string;
	homepageTesting: boolean;
}
const HomePage: NextPageWithLayout<PageProps> = ({ initialTopicCode, homepageTesting }) => {
	if (homepageTesting) {
		return <MediathekHomePageB initialTopicCode={initialTopicCode} />;
	}
	return <MediathekHomePageA initialTopicCode={initialTopicCode} />;
};

const setUpLdContexts = async (
	email: string | null,
	ip: string,
	userAgent: string
): Promise<LDContext> => {
	if (email) {
		return {
			kind: 'multi' as const,
			user: {
				key: await getSHA256Hash(email),
				email
			},
			ip: {
				key: await getSHA256Hash(ip + userAgent)
			}
		};
	}
	return {
		kind: 'ip',
		key: await getSHA256Hash(ip + userAgent),
		anonymous: true
	};
};

export const getServerSideProps: GetServerSideProps = async (context) => {
	const cookie = context.req.headers.cookie;
	const ip = context.req.socket.remoteAddress || '';
	const userAgent = context.req.headers['user-agent'] || '';
	const apolloClient = initializeApollo(null, {
		batchHttpLinkOptions: { headers: { cookie } }
	});
	const ldClient = await getLaunchDarklyServerClient();
	const locale = (context.locale || DEFAULT_LOCALE) as Locale;
	const initalTopic = await getInitialTopic(apolloClient, context);
	let { selectedTopicCode } = initalTopic;
	const { fromQuery } = initalTopic;
	const promises: Array<Promise<unknown>> = [];
	let homepageTesting = false;

	try {
		const { authenticatedUser, user, userMembership } =
			await SsrAuthQueries.ssrFetchUserAndMembership(apolloClient, locale);
		if (authenticatedUser && user) {
			const profileCompleted = isProfileComplete(user, locale);
			if (!profileCompleted) {
				return {
					redirect: {
						destination: createURL('/user/complete-profile', 'catalog', true).url,
						permanent: false
					}
				};
			}
			homepageTesting = await ldClient?.variation(
				'homepage-testing',
				await setUpLdContexts(user.email, ip, userAgent),
				false
			);
			const resSpecialities = await apolloClient.query<CurrentUserSpecialityQuery>({
				query: CurrentUserSpeciality
			});
			// query topic takes precedence over user speciality
			if (!fromQuery) {
				selectedTopicCode =
					resSpecialities.data.topicDocuments.data[0]?.code ?? ACTIVE_TOPIC_CODE;
			}
			if (userMembership?.addedToGroupMembershipDateTime) {
				await apolloClient.query<Queries.GetAddOnContentsQuery>({
					query: Queries.GetAddOnContents,
					variables: getAddonsQueryVariables()
				});
			}
			await apolloClient.query<RecentlyStartedCoursesQuery>({
				query: RecentlyStartedCourses
			});
		} else {
			homepageTesting = await ldClient?.variation(
				'homepage-testing',
				await setUpLdContexts(null, ip, userAgent),
				false
			);
		}
		// log the error and allow proceeding, queries will be done on client side.
	} catch (err) {
		console.error(err);
	}

	// topics
	promises.push(
		apolloClient.query<TopicsForSelectorQuery>({
			query: TopicsForSelector
		})
	);

	promises.push(
		apolloClient.query<SearchForTopicsQuery>({
			query: SearchForTopics,
			variables: getTopicsSearchQuery({
				pageNumber: 0,
				pageSize: TOPICS_PER_PAGE,
				levels: ['0', '1'],
				parentCode: null
			})
		})
	);

	// courses
	promises.push(
		apolloClient.query<TopicSortOrderQuery>({
			query: TopicSortOrder,
			variables: getCustomTopicSortOrder(selectedTopicCode)
		})
	);
	promises.push(
		apolloClient.query<MediathekCoursesQuery>({
			query: MediathekCourses,
			variables: getFetchCoursesVariables(getCountryCode(locale))
		})
	);

	try {
		await Promise.all(promises);
		return addApolloState(apolloClient, {
			props: {
				initialTopicCode: selectedTopicCode,
				homepageTesting: homepageTesting ?? null
			}
		});
	} catch (err) {
		console.error(err);
		return {
			props: {
				initialTopicCode: selectedTopicCode,
				homepageTesting: homepageTesting ?? null
			}
		};
	}
};

export default HomePage;
