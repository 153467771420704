import React from 'react';
import { ContentsByTopics } from './ContentsByTopics/ContentsByTopics';
import { MediathekSectionHeader } from './MediathekSectionHeader';
import { MediathekLectureItem } from '../../components/MediathekLectureItem/MediathekLectureItem';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { showFreeBadge, useMembershipContext } from 'common';

const useStyles = makeStyles((theme) => ({
	sectionTitle: {
		marginBlockStart: 64,
		marginBlockEnd: 75,

		[theme.breakpoints.down('sm')]: {
			marginBlockStart: 15,
			marginBlockEnd: 40,
			color: '#59748D'
		}
	}
}));

type LecturesSectionProps = {
	selectedTopicCode: string;
};

export const LecturesSection = ({ selectedTopicCode }: LecturesSectionProps) => {
	const classes = useStyles();
	const { formatMessage } = useIntl();
	const { isMember } = useMembershipContext();

	return (
		<>
			<MediathekSectionHeader
				text={formatMessage({ id: 'catalog.home.lectures' })}
				headerClassname={classes.sectionTitle}
			/>
			<ContentsByTopics
				renderItem={(item, index) => (
					<MediathekLectureItem
						lecture={item}
						isFree={showFreeBadge({
							contentIsFree: item.isFree,
							parentContentIsFree: item.parent?.isFree,
							isMember
						})}
						index={index}
					/>
				)}
				selectedTopicCode={selectedTopicCode}
			/>
		</>
	);
};
