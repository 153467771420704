import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		container: {
			display: 'flex',
			flexWrap: 'wrap',
			padding: theme.spacing(3, 1, 1, 1),

			[theme.breakpoints.up('md')]: {
				flexWrap: 'nowrap',
				alignItems: 'center',

				padding: theme.spacing(6, 3, 0, 3)
			}
		},
		titleContainer: {
			flexBasis: '100%',
			padding: theme.spacing(0, 2),

			[theme.breakpoints.up('md')]: {
				flexBasis: '50%',
				order: 0,
				marginBottom: theme.spacing(0),
				padding: theme.spacing(0)
			},

			[theme.breakpoints.up('lg')]: {
				flexBasis: '65%'
			}
		},
		title: {
			textAlign: 'start',
			marginBottom: theme.spacing(1)
		},
		nameTitle: {
			textAlign: 'start',
			color: theme.palette.primary.main,
			fontWeight: 'normal',
			marginBottom: theme.spacing(3)
		},
		companyContainer: {
			flexBasis: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: theme.spacing(0),
			marginBottom: theme.spacing(4),

			[theme.breakpoints.up('md')]: {
				flexBasis: '50%',
				order: 1,
				marginBottom: theme.spacing(0),
				padding: theme.spacing(0, 0, 0, 12)
			},

			[theme.breakpoints.up('lg')]: {
				flexBasis: '35%'
			}
		},
		companyLogo: {
			width: 80,
			height: 80,

			[theme.breakpoints.up('md')]: {
				width: 160,
				height: 160
			}
		},
		companyName: {
			fontWeight: 600,
			textAlign: 'center',
			color: '#59748D'
		},
		membershipContainer: {
			marginTop: theme.spacing(3),
			display: 'flex',
			alignItems: 'center',

			[theme.breakpoints.up('md')]: {
				marginTop: theme.spacing(1)
			}
		},
		membershipIcon: {
			width: 24,
			height: 24,
			marginRight: theme.spacing(1.5),
			color: theme.palette.secondary.main
		},
		membershipTitle: {
			color: theme.palette.primary.main,
			display: 'flex',
			alignItems: 'center'
		},
		addonsContainer: {
			marginBottom: theme.spacing(5),
			[theme.breakpoints.up('sm')]: {
				marginBottom: theme.spacing(9)
			}
		}
	}),
	{
		name: 'GroupMembersHeader'
	}
);

export default useStyles;
