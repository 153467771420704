import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import { TitleTooltip, Typography } from 'components';
import {
	formatters,
	FreeBadge,
	getProgressPercentage,
	useChangeURL,
	buildURLWithParams,
	MEDIATHEK_URLS,
	Types,
	VideoRating
} from 'common';
import { LectureThumbnail } from 'catalog-lib';

import useStyles from './LectureItemWithProgress.styles';
import { LectureItemWithProgressProps } from './LectureItemWithProgress.types';

const LectureItemWithProgress = (props: LectureItemWithProgressProps) => {
	const {
		thumbnailUrl,
		duration,
		isFree,
		title,
		subtitle,
		time,
		contentId,
		parentContentId,
		parentContentType,
		rating
	} = props;
	const changeURL = useChangeURL();
	const classes = useStyles();
	const progressPercentage = getProgressPercentage(time, duration);

	const handleClick = () => {
		let parentContentIdToUse = null;
		if (
			parentContentType === Types.ContentType.Course ||
			parentContentType === Types.ContentType.Webinar
		) {
			parentContentIdToUse = parentContentId;
		}
		let newUrl = buildURLWithParams(MEDIATHEK_URLS.content, [
			parentContentIdToUse ?? contentId
		]);
		const videoPlayingUrlPath = `/${contentId}`;
		if (parentContentIdToUse) {
			newUrl += videoPlayingUrlPath;
		}
		changeURL(newUrl, 'catalog', true);
	};

	return (
		<Box className={classes.container} onClick={handleClick}>
			<Box>
				<Box className={classes.imageContainer}>
					<div className={classes.playerContainer}>
						<LectureThumbnail thumbnailUrl={thumbnailUrl} />
						{duration && (
							<Typography
								className={classes.duration}
								title={formatters.formatDurationInSeconds(duration)}
							/>
						)}
						{isFree && <FreeBadge classes={{ freeBadge: classes.freeBadge }} />}
					</div>
				</Box>
			</Box>
			<LinearProgress
				variant="determinate"
				color="secondary"
				className={classes.progress}
				value={progressPercentage}
			/>
			<Box className={classes.descriptionContainer}>
				<VideoRating rating={rating} />
				<TitleTooltip title={title}>
					<Typography
						title={title}
						className={classes.title}
						color="titleActive"
						variant="textSmall"
					/>
				</TitleTooltip>
				{subtitle && (
					<Typography title={subtitle} className={classes.subtitle} variant="caption" />
				)}
			</Box>
		</Box>
	);
};

export default LectureItemWithProgress;
