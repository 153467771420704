import React, { useState } from 'react';
import classnames from 'classnames';
import { Box } from '@mui/material';
import { AddonCard, AddonCardSkeleton } from 'catalog-lib';
import {
	createURL,
	getAddonCatalogLink,
	getCurrentWatchingContentId,
	useMembershipContext,
	useUserContext
} from 'common';
import { HorizontalList } from 'components';

import useStyles from './MediathekStartedOnDemandCourses.styles';
import { useRecentlyStartedCoursesQuery } from '../../../graphql/catalog/queries';

type Props = {
	className?: string;
};

const MediathekStartedOnDemandCourses = (props: Props) => {
	const { className } = props;
	const userCtx = useUserContext();
	const { user } = userCtx;
	const { isMember } = useMembershipContext();
	const [offset, setOffset] = useState(0);
	const classes = useStyles();
	const { data, loading } = useRecentlyStartedCoursesQuery({});

	const courses = (data?.recentlyStartedCourses || []).map((coursesData) => coursesData?.course);

	if (!user || !isMember) {
		return null;
	}

	const pageSize = 3;
	const visibleList = courses.slice(offset, offset + pageSize);

	return (
		<Box className={classnames(classes.container, className)}>
			<Box>
				<HorizontalList
					items={visibleList}
					renderItem={(course) => {
						const progressLink = createURL(
							`/ondemand-kurs/${course.contentId}`,
							'catalog',
							true
						).url;
						const contentId = getCurrentWatchingContentId(course.videos ?? []);
						const currentVideo = course.videos?.find(
							(scheduleItem) => scheduleItem.contentId === contentId
						);
						const courseLink = getAddonCatalogLink(
							course.contentId,
							currentVideo?.contentId
						);
						return (
							<AddonCard
								fullContent={course}
								className={classes.card}
								isCompact
								progressLink={progressLink}
								courseLink={courseLink}
							/>
						);
					}}
					numberOfItemsInRow={pageSize}
					totalCount={courses.length}
					offset={offset}
					onOffsetChange={setOffset}
					classes={{
						horizontalListContainer: classes.horizontalListContainer,
						item: classes.cardListItem
					}}
					renderSkeletonItem={() => (
						<AddonCardSkeleton className={classes.card} isCompact />
					)}
					isSkeletonVisible={loading}
				/>
			</Box>
		</Box>
	);
};

export default MediathekStartedOnDemandCourses;
