import React from 'react';
import { Box } from '@mui/material';
import classnames from 'classnames';
import {
	MembershipTitle,
	useMembershipContext,
	UserCompanyLogo,
	useUserContext,
	useUserFullName
} from 'common';
import { Icon, Typography } from 'components';

import useStyles from './UserWelcomeHeader.styles';

type Props = {
	className?: string;
};

const UserWelcomeHeader = (props: Props) => {
	const { className } = props;
	const { user } = useUserContext();
	const { userMembership } = useMembershipContext();
	const hasGroupMembership = Boolean(userMembership?.addedToGroupMembershipDateTime);
	const { name: companyName, logoUrl: companyLogoUrl } = user?.company || {};
	const userFullName = useUserFullName(user);

	const classes = useStyles(props);

	return (
		<Box className={classnames(classes.container, className)}>
			{hasGroupMembership && (
				<Box className={classes.companyContainer}>
					<UserCompanyLogo companyLogo={companyLogoUrl} className={classes.companyLogo} />
					<Typography variant="h3" title={companyName} className={classes.companyName} />
				</Box>
			)}
			<Box className={classes.titleContainer}>
				<Typography
					variant="h1"
					color="primary"
					className={classes.title}
					localeId="catalog.mediathek.addons.title"
				/>
				<Typography variant="h4" className={classes.nameTitle}>
					{userFullName}
				</Typography>
				{userMembership && hasGroupMembership && (
					<Box display="flex" className={classes.membershipContainer}>
						<Icon icon="success" className={classes.membershipIcon} />
						<MembershipTitle
							name={userMembership.membership.name}
							membershipType={userMembership.membership.membershipType}
							className={classes.membershipTitle}
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default UserWelcomeHeader;
