import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from 'components';
import { Types, useUserContext, useUserFullName } from 'common';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	titleContainer: {
		flexBasis: '100%',
		padding: theme.spacing(4),

		[theme.breakpoints.up('md')]: {
			flexBasis: '50%',
			order: 0,
			padding: theme.spacing(8, 3, 7, 3)
		},

		[theme.breakpoints.up('lg')]: {
			flexBasis: '65%'
		}
	},
	title: {
		textAlign: 'start',
		marginBottom: theme.spacing(1)
	},
	subtitle: {
		textAlign: 'start',
		color: theme.palette.primary.main,
		fontWeight: 'normal',
		marginBottom: theme.spacing(3)
	}
}));

const WelcomeMessage = () => {
	const classes = useStyles();
	const { isLoggedIn, user } = useUserContext();
	const userFullName = useUserFullName(user);
	const isPha = user?.accountType === Types.AccountType.Pha;
	return (
		<Box className={classes.titleContainer}>
			{isLoggedIn ? (
				<>
					<Typography
						localeId="catalog.mediathek.addons.title"
						className={classes.title}
						variant="h1"
						color="primary"
					/>
					{!isPha && (
						<Typography variant="h4" className={classes.subtitle}>
							{userFullName}
						</Typography>
					)}
				</>
			) : (
				<>
					<Typography
						localeId="catalog.mediathek.home.guest.title"
						className={classes.title}
						variant="h1"
						color="primary"
					/>
					<Typography
						localeId="catalog.mediathek.home.guest.subtitle"
						variant="h4"
						className={classes.subtitle}
					/>
				</>
			)}
		</Box>
	);
};

export default WelcomeMessage;
